export const reviews = [
    {
        name: 'Biana Dunca',
        review: `Îmi doream de foarte multe vreme o ie și pot să spun cu cea mai mare plăcere că am luat cea mai bună decizie apelând la Ie de Maramu'. Rezultatul este uimitor și abia aștept să o port la cât mai multe evenimente. Recomand cu încredere! :)`,
    },
    {
        name: 'Cristina Chereji Marcus',
        review: `Multumesc din suflet. Ia este absolut minunata, lucrata impecabil, cu daruire si multa iubire. ❤️`,
    },
    {
        name: 'Lucia Rad',
        review: `Superbe modelele, lăudabilă inițiativa! Dragoste și respect pentru tradiții! Felicitări!`,
    },
    {
        name: 'Raluca Ardelean',
        review: `Aici se fac lucrusoare minunate, care vin tocmai din suflet. Se lucreaza cu drag si pasiune, si ce iese din mana oamenilor astora nu e un produs, e ceva viu.`,
    },
];