export const faqData = [
    {
        question: 'Ce tip de material folosiți pentru realizarea unei ii?',
        answer: `În momentul de față, folosim două tipuri de pânză: matador și datina, în funcție de tehnica de cusut necesară realizării modelului comandat.`,
    },
    {
        question: 'Aveți produse în stoc?',
        answer: 'Având în vedere că marea majoritate a iilor le realizăm pe comandă, nu prea avem stocuri. Se întâmplă, rareori ce-i drept, să avem ii gata de livrare, direct din stoc, iar atunci vă anunțăm cu drag.',
    },
    {
        question: 'În cât timp e gata o ie?',
        answer: 'Termenul de realizare al unei ii variază între 3-5 săptămâni în funcție de tehnica de cusut și de volumul comenzilor deja în lucru.',
    },
    {
        question: 'Cât costă o ie?',
        answer: 'Prețul unei ii începe de la 600 lei și în funcție de complexitatea modelului poate ajunge până la 1200-1500 lei. Oricum, în momentul plasării comenzii sau solicitării unui model anume, vei fi informat atât de preț cât și de termenul de execuție.',
    },
    {
        question: 'De unde pot să îmi cumpăr o ie?',
        answer: 'Direct de aici de pe site, în secțiunea de magazin online, din magazinul de pe Facebook, din platforma de Instagram, de pe Etsy sau fizic, din magazinul La Blouse Roumaine, din București.',
    },
    {
        question: 'Cât costă transportul?',
        answer: 'Indiferent de valoarea comenzii, transportul este gratuit în țară, prin servicii de curierat. Pentru comenzi în afara țării, vom stabili de comun acord modalitatea de livrare.',
    },
    {
        question: 'Trimiteți produse și în străinătate?',
        answer: 'Da, am primit și onorat comenzi din diferite colțuri ale lumii, din Australia în America și cam peste tot prin Europa.',
    },
    {
        question: 'Pot să plătesc și în euro?',
        answer: 'Da, se pot cumpăra produse plătind în euro, la cursul BNR din ziua plasării comenzii.',
    },
    {
        question: 'E nevoie de un avans sau plată parțială?',
        answer: 'Da, e nevoie de un avans de 50% din valoarea produsului, achitat în momentul plasării comenzii și transmiterii dimensiunilor.',
    },
    {
        question: 'Realizați și modele de ii la comandă?',
        answer: 'În principiu, comercializăm ii deja create de noi dar dacă ai ceva model ce îți place, ne poți trimite mai multe detalii și te anunțăm dacă îl putem realiza sau nu.',
    }
];