export const stagesData = [
    {
        title: 'Alegerea tipului de pânză',
        text: 'Alegem dintre matador și datina, pânza care se potrivește mai bine cu tehnica de cusut aleasă și modelul dorit. Datina o folosim la modelele cu ciur iar matador la restul modelelor.',
    },
    {
        title: 'Colectarea și verificarea dimensiunilor',
        text: 'În procesul de comandă/validare al comenzii îți solicităm dimensiunile conform ghidului, dimensiuni ce vor da forma perfectă iei.',
    },
    {
        title: 'Relizarea croiului',
        text: 'Bazat pe dimensiuni, croim ia și tăiem pânza în forma dorită, numai bună de următoarea etapă.',
    },
    {
        title: 'Înșeilatul: sau surfilatul manual',
        text: 'Coaserea manuală a fiecărei margini, pentru a evita desprinderea materialului.',
    },
    {
        title: 'Realizarea modelului',
        text: 'Aici unul din cei 12 meșteri populari petrece între 2 și 4 săptămâni la coaserea modelului, cu fir tras și în cruce sau la tăierea acestuia la modelul cu ciur.',
    },
    {
        title: 'Coaserea, spălarea, apretarea și călcarea',
        text: 'În această etapă sunt prinse toate părțile croite: mâneci, față, spate, piept împreună, spălată ia manual și apretată iar apoi călcată.',
    },
    {
        title: 'Împachetarea și expedierea coletului',
        text: 'Etapa în care așteptarea a luat sfârșit și aștepți cu sufletul la gură curierul.',
    },
    {
        title: 'Recepția și probarea iei',
        text: 'Aici tu știi cel mai bine ce și cum faci, dar tare mult te rugăm să ne trimiți o poză și să ne spui două-trei cuvinte sub forma unui review.',
    },
    {
        title: 'Condiții de păstrare',
        text: 'Ca ia să ajungă și nepoților, am pregătit pentru tine în colet un ghid de păstrare, sub forma unui gând bun din Maramu’',
    }
];