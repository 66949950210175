import React from 'react';
import './footer.scss';

const Footer = () => (
    <div className='footer w-100 p-3 text-center'>
        Ie de Maramu' 2020 |
        <a href="https://www.facebook.com/iedemaramu" target="_"><i className='fa fa-facebook-square ml-3'></i></a>
        <a href="https://www.instagram.com/iedemaramu" target="_"><i className='fa fa-instagram ml-3'></i></a>
    </div>
);

export default Footer;