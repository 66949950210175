import React from 'react';
import './contact.scss';
import ContactForm from './contact_form';
import { isMobile } from "react-device-detect";

export default class Contact extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            from: 0,
            selectedProductName: null,
            selectedProductType: null,
        }
    }

    handleClick = (event) => {
        this.setState({
            from: parseInt(event.currentTarget.dataset.from)
        })
    }

    selectProduct = (event, product = null) => {
        this.setState({
            selectedProductName: product || event.currentTarget.dataset.product,
            selectedProductType: product ? null : event.currentTarget.dataset.type
        })
    }

    render() {
        return(
            <div className='container p-3 shadow-lg mt-5 contact-container mb-5'>
                <h3 className='text-center'>Contact</h3>
                <hr className='separator'></hr>
                <p className='text-center'>
                    Completează formularul de mai jos iar noi te vom contacta în curând!<br></br>
                </p>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col m-3 pt-4 text-center bg-light shadow-lg border'>
                                        <i className='far fa-envelope fa-2x'></i>
                                        <h3>Adresa de Email</h3>
                                        <p>contact@iedemaramu.ro</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col m-3 pt-4 text-center bg-light shadow-lg border'>
                                        <i className='fa fa-phone fa-2x'></i>
                                        <h3>Număr de telefon</h3>
                                        <p><a href='tel:0749537565' className='text-primary'>0749 537 565</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            !isMobile ?
                            (
                                <div className='col'>
                                    <ContactForm selectProduct={this.selectProduct} selectedProductName={this.state.selectedProductName} selectedProductType={this.state.selectedProductType}></ContactForm>
                                </div>
                            )
                            : null
                        }
                    </div>
                    {
                        isMobile ?
                        (
                            <div className='col mt-3'>
                                <ContactForm selectProduct={this.selectProduct} selectedProductName={this.state.selectedProductName} selectedProductType={this.state.selectedProductType}></ContactForm>
                            </div>
                        )
                        : null
                    }
                </div>
            </div>
        )
    }
}
