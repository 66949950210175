export const teamData = [
    {
        name: 'Mădălina',
        function: 'design de produs și logisitice',
    },
    {
        name: 'Steli',
        function: 'marketing și customer support',
    },
    {
        name: 'Alexandra',
        function: 'grafică și design',
    },
    {
        name: 'Alex',
        function: 'poze de produs',
    }
] 