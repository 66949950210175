import React from 'react';
import './team.scss';
import { teamData } from './team-data';

export default class Team extends React.Component{
    render() {
        const teamMembers = teamData.map((member) => (
            <li className="list-group-item m-1" key={`team_member_${member.name}`}>
                <h3>{member.name}</h3>
                <p className='text-disabled'>- {member.function} -</p>
            </li>
        ))
        return (
            <div className='team-background'>
                <div className='container p-3  mt-5 team-container'>
                    <h3 className='text-center'>Echipa</h3>
                    <hr className='separator'></hr>
                    <p className='text-center'>Am început povestea Ie de Maramu' în noiembrie 2017 cu 2 femei pasionate de cusut și port popular iar azi echipa numără 11 meșteri populari ce realizează manual produsele dragi vouă.</p>
                    <img src={require(`./images/echipa_iedemm.jpg`)} className="card-img-top mw-10 m-1" alt={'Echipa Ie de Maramu\''}></img>
                    <p className='text-center mt-3'>Pe lângă meșteri, mai avem o mână de oameni ce se ocupă din spate, ca tot acest proiect să fie așa cum îl știți voi azi.</p>
                    <ul className="list-group text-dark text-center">
                        {teamMembers}
                    </ul>
                </div>
            </div>
        )
    }
}
