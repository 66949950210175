import React from 'react';
import './stages.scss';

class Stage extends React.Component {
    render = () => {
        const { stage, index } = this.props;
        return (
            <div className='col border m-2 p-2'>
                <h1 className='text-right text-muted stage-number'>{index + 1}</h1>
                <h2>{stage.title}</h2>
                <p className='text-muted'>{stage.text}</p>
            </div>
        )
    }
}

export default Stage;