export const aboutData = [
    {
        title: 'Buni, când ai început să coși?',
        text: 'Api draga mamii, am început când o fo musai. Am cusut prin casă prima dată ce era de tomnit, apoi după ce am început cu școala, șezători și alti cele, atunci pot zîce că am început mai de-anumea.',
        images: ['about-1.jpg', 'about-2.jpg']
    },
    {
        title: 'Și de la cine ai învățat?',
        text: `De cusut, de la mama am învățat și apoi cum schimbam modele cu fetele din sat, pot zîce că ne învățam și noi, una pe alta, că atunci nu erau atâtea modele unde să vezi cum să face pas cu pas. Mai greșam, mai desfăceam, cosam iară, multă pânză am strâcat până am reușit să învăț.`,
        images: ['about-7.jpg', 'about-8.jpg']
    },
    {
        title: 'Ce îți place să coși cel mai mult?',
        text: `Ei, ce îmi place, știi tare bine că ciuru mi-i tare drag, și tare dor mi-a fi de el. Îi mai cu migală, da îi ce îmi place mai mult, că doară asta m-ai întrebat.`,
        images: ['about-3.jpg', 'about-6.jpg']
    },
    {
        title:`Ai învățat și pe alții? Cam câți?`,
        text:`De învățat i-am învățat, nu știu ei de o priceput ori de mai cos acuma. Cum cosam cu fetele la șezători, ori sara pe poduț ori vara la umbră în grădină, veneau și fete mai mici la care le mai arătam. Și să zîc sau să nu zîc, și pe Dorina tot eu am învățat-o. Și tare drag mi-i de ea când văd că la rându-i te învață și ea pe tine.`,
        images: ['about-4.jpg', 'about-5.jpg']
    }
]