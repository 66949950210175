import React from 'react';
import './prices.scss';

export default class Prices extends React.Component{
    render() {
        return (
            <div className='container p-3 prices-container mt-5 border'>
                <h3 className='text-center'>Prețuri</h3>
                <hr className='separator'></hr>
                <p className='text-center'>
                    Produsele realizate de noi sunt cu prețuri între 50 și 1.800 de lei. Încep de la articole mai simple, precum papioane, articole decorative, pălării și continuăm cu cele mai complexe, precum tricouri, brâuri, ii, cămeși și costume populare complete.<br></br>
                    În general, o ie este la prețul de 600-700 lei, iar pentru realizarea ei e nevoie de 3-4 săptămâni, dacă e cusută în cruci sau cu fir tras, iar dacă e cu ciur realizarea ei durează 4-5 săptămâni.<br></br>
                    Prețul unui produs include materialele folosite, plata meșterului sau a meșterilor ce lucrează pentru realizarea produsului și costul de marketing/logistică.<br></br>
                </p>
            </div>
        )
    }
};