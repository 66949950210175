import React from 'react';
import './products.scss';
import { productsData } from './products-data';
import Product from './product';
import { isMobile } from "react-device-detect";

class Products extends React.Component {
    render = () => {
        const products = productsData.map((productData) => <Product {...productData} key={`product_${productData.name}`}></Product>)
        return (
            <div className='products-container mt-5' id='products'>
                <div className='container p-3'>
                    <h3 className='text-center'>Produse</h3>
                    <hr className='separator'></hr>
                    <p className='text-justify row p-3'>
                        În momentul de fată Ie de Maramu are în portofoliu ii pentru femei, cămeși pentru bărbați, costume din două piese pentru fetițe și băieți și papioane.
                        Toate sunt cusute manual, doar tehnica diferă. Avem cusutul în cruci, cusutul cu fir tras și cusutul cu ciur, cel mai migălos dar și cel mai spectaculos, zicem noi.
                    </p>
                    {
                        isMobile ? (
                            products
                        ) : (
                            <div className='row'>
                                {products}
                            </div>
                        )
                    }
                </div>
            </div>
        );
    };
};

export default Products;