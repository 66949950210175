import React from 'react';

class Product extends React.Component{
    render = () => {
        const { name, image, link } = this.props;
        return(
            <div className='col'>
                <div className="card m-1">
                    <img src={require(`./images/${image}`)} className="card-img-top mw-10" alt={name}></img>
                    <div className="card-body text-center">
                    <p className="card-title text-dark text-center ">{name}</p>
                    <a href={link} target='_' className="btn btn-danger">Magazin online</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Product;