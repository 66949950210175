export const modelsData = [
    {
        name: 'Ia Florelia',
        description: 'Cusută în cruce, cu flori multicolore la baza gâtului și câte un buchet pe fiecare mânecă. Modelul este tipic zonei, cu croi pătrat la baza gâtului. Pânza folosită e pânza matador și coasem cu ațe de Tălmaciu. Realizarea acestui model durează de regulă 3-4 săptămâni, în funcție și de comenzile aflate în lucru.',
        image: 'ie-florelia.jpg'
    },
    {
        name: 'Ia Rodica',
        description: 'Cusută cu fir tras, cu flori de un cărămiziu, maro, galben, portocaliu dar cu frunze verzi. Și acest model este cu croi pătrat la baza gâtului. La manșete și în zona pătrată de la gât are un rând de cipcă, realizată separat și cusută la finalizarea iei. Acest model se realizează în aproximativ 4 săptămâni, fiind mai migălos decât cusutul în cruce.',
        image: 'ie-rodica.jpg',
    },
    {
        name: 'Ia Dorina',
        description: 'Un mix între cusutul în cruce și ciur, este rodul muncii și creației persoanei ce îi poartă numele. Dorina, mama din poveste a venit cu idea acestui model pentru a încerca combinația de ciur cu cea de cusut. Nu am elaborat un model încărcat, am păstrat simplitatea tocmai pentru a nu merge foarte departe nici cu termenul de execuție, care e de aproximativ 4-5 săptămâni. Pânza folosită e pânză datina, deoarece avem nevoie de pânză deasă pentru a realiza ciurul.',
        image: 'ie-dorina.jpg',
    },
    {
        name: 'Ia Mădălina și Ia Mărioara',
        description: 'Sunt cele două ii realizate exclusiv prin tehnica cu ciur. Aici vă vom spune mai multe detalii și așa veți reuși să înțelegeți de ce e așa de specială această ie și cum de arată atât de bine. Cum am menționat avem nevoie de o pânză un pic mai groasă, decât cea folosită la modelele florale, cea matador. Deci folosim pânza datina care ne oferă consistența necesară. Odată modelul ales și dimensiunile iei avute se începe efectiv realizarea ciurului. Se taie pe pânză un pătrat cu dimensiunea de aproximativ jumate de centimetru și fiecare latură a lui e cusută cu ață albă, de jur împrejur pentru a nu se desprinde restul de pânză. Prin urmare, modelul e creat printr-o înșiruire de mini-pătrățele tăiate și apoi cu marginile cusute. Vă lăsăm pe voi să numărați pe modelul Ie Mădălina, câte pătrățele sunt. Odată avut numărul noi vă spunem și cât durează să tai, coși, tai, coși pentru a face un model de bază. La final ne iese undeva la 5 săptămâni de lucru. A se lua în calcul că nu e un program de 8 ore, cu 5 zile pe săptămâna. Asta e imposibil, mai ales când meșterul popular e o gospodină care are de dus la bun sfârșit și sarcini casnice. Plus că nu e nici sănătos pentru ochi, să lucrezi doar cu ciur, pe alb pur, ore și ore în șir.',
        image: 'ie-madalina.jpg',
    },
    {
        name: '',
        description: '',
        image: 'ie-marioara.jpg',
    }
]
