import React from 'react';
import './header.scss';

class Header extends React.Component {
  render = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark navbar-text-center fixed-top">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
        
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav m-auto">
                    <li className="nav-item">
                        <div className="nav-link" data-goto='main-container' onClick={this.handleClick} data-toggle="collapse" data-target="#navbarSupportedContent">Acasă</div>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link" data-goto='about-us-container' onClick={this.handleClick} data-toggle="collapse" data-target="#navbarSupportedContent">Despre Noi</div>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link" data-goto='products-container' onClick={this.handleClick} data-toggle="collapse" data-target="#navbarSupportedContent">Produse</div>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link" data-goto='prices-container' onClick={this.handleClick} data-toggle="collapse" data-target="#navbarSupportedContent">Prețuri</div>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link" data-goto='team-container' onClick={this.handleClick} data-toggle="collapse" data-target="#navbarSupportedContent">Echipa</div>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link" data-goto='contact-container' onClick={this.handleClick} data-toggle="collapse" data-target="#navbarSupportedContent">Contact</div>
                    </li>
                    <a href="http://shop.iedemaramu.ro/" target="_"><button className="btn btn-danger navbar-btn online-shop-btn" data-toggle="collapse" data-target="#navbarSupportedContent">Magazin online!</button></a>
                </ul>
            </div>
        </nav>
    );
  };

  handleClick = (event) => {
    window.scrollTo({
        left: 0,
        top: document.getElementsByClassName(event.currentTarget.dataset.goto)[0].offsetTop -  document.getElementsByClassName('navbar')[0].offsetHeight,
        behavior: 'smooth'
    })
  }
};

export default Header;
