import React from 'react';
import ImageCarousel from './image-carousel';
import { isMobile } from "react-device-detect";

class AboutRow extends React.Component {
    render = () => {
        return (
            <div className='container about-row'>
                <div className='row mt-5'>
                    {this.imageContent()}
                    {this.textContet()}
                </div>
            </div>
        );
    };

    textContet = () => {
        const { title, text } = this.props;
        return (
            <div className="col-md text-justify shadow-lg p-3 rounded mt-1">
                <h3 className="text-center">{title}</h3>
                <hr></hr>
                {text}
            </div>
        );
    };

    imageContent = () => {
        const { images, placeImageRight } = this.props;
        let classNames = "col-md";
        if (placeImageRight && !isMobile) classNames += ' order-last';
        return (
            <div className={classNames}>
                <ImageCarousel imageNames={images} placeImageRight={placeImageRight}></ImageCarousel>
            </div>
        );
    };
};

export default AboutRow;