export const urlData = () => {
    if (document.getElementsByClassName("navbar")) {
        const navbarHeight = document.getElementsByClassName("navbar")[0].offsetHeight;
        return {
            acasa: {
                offsetTop: 0,
            },
            despre: {
                offestTop: document.getElementsByClassName("about-us-container")[0].offsetTop - navbarHeight,
                urlHash: 'despre',
                goTo: 'about-us-container',
            },
            produse: {
                offestTop: document.getElementsByClassName("products-container")[0].offsetTop - navbarHeight,
                urlHash: 'produse',
                goTo: 'products-container',
            },
            preturi: {
                offestTop: document.getElementsByClassName("prices-container")[0].offsetTop - navbarHeight,
                urlHash: 'preturi',
                goTo: 'prices-container',
            },
            echipa: {
                offestTop: document.getElementsByClassName("team-container")[0].offsetTop - navbarHeight,
                urlHash: 'echipa',
                goTo: 'team-container',
            },
            comanda: {
                offestTop: document.getElementsByClassName("contact-container")[0].offsetTop - navbarHeight,
                urlHash: 'comanda',
                goTo: 'contact-container',
            },
        }
    }
    else {
        return {};
    }
}