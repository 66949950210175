import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import IeDeMaramu from '../ie-de-maramu/main/ie';

class App extends React.Component {
  render = () => {
    return (
      <Router>
        <Switch>
          <Route exact path='/' component={IeDeMaramu}>
          </Route>
        </Switch>
      </Router>
      )
  };
}

export default App;
