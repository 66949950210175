export const productsData = [
    {
        name: 'Ii femei',
        image: 'ii_femei.jpg',
        link: 'http://shop.iedemaramu.ro/ii-femei',
    },
    {
        name: 'Cămeși bărbați',
        image: 'camesi_barbati.jpg',
        link: 'http://shop.iedemaramu.ro/camesi-barbati',
    },
    {
        name: 'Costume copii',
        image: 'costume_copii.jpg',
        link: 'http://shop.iedemaramu.ro/costume-copii',
    },
    {
        name: 'Articole urbane',
        image: 'articole_urbane.jpg',
        link: 'http://shop.iedemaramu.ro/articole-urbane',
    },
    {
        name: 'Accesorii',
        image: 'accesorii.jpg',
        link: 'http://shop.iedemaramu.ro/accesorii',
    },
    {
        name: 'Pentru casă',
        image: 'pentru_casa.jpg',
        link: 'http://shop.iedemaramu.ro/pentru-casa',
    },
]