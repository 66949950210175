import React from 'react';
import {Animated} from "react-animated-css";
const validator = require('email-validator');

export default class ContactForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            nume: '',
            mail: '',
            messageSent: false,
            gdpr: false,
        }
    }

    handleSubmit = (e) => {
        return;
        //TO DO Ionut Google Spreadsheets and notification
    }

    validateForm = () => {
        let valid =  true;
        const newState = this.state;

        if (newState.nume === '' || newState.nume === -1) {
            newState.nume = -1;
            valid = false;
        }

        if (!validator.validate(newState.mail)) {
            newState.mail = -1;
            valid = false;
        }

        if (newState.mesaj === '' || newState.mesaj === -1) {
            newState.mesaj = -1;
            valid = false;
        }

        if (!newState.gdpr) {
            newState.gdpr = -1;
            valid = false;
        }

        if (!valid) {
            this.setState(newState);
        };
        return valid;
    }

    render() {
        if (this.state.messageSent) {
            return (
                <Animated animationIn="bounceIn" animationOut="fadeOutLeft" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                    <div className='container'>
                        <div className='message-sent p-3 shadow-lg text-center border border-success'>
                            <h3 className='text-success'>Mesajul tău a fost trimis! <i className='fa fa-check-circle text-success'></i></h3>
                            <p className='text-muted'>Te vom contacta în curând.</p>
                        </div>
                    </div>
                </Animated>
            )
        }
        return (
            <div className='container'>
                <form className='p-3 shadow-lg' action='send' method='POST'>
                    <div className='row'>
                        <div className='col text-center m-1 text-danger'>
                            <i className='fas fa-exclamation-triangle'></i> Încă lucrăm la această funcționalitate
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <input
                                className={`form-control ${this.state.nume === -1 ? 'is-invalid' : ''}`}
                                type='text'
                                name='nume'
                                placeholder='Nume'
                                onChange={e => this.setState({ nume: e.target.value })}
                                disabled
                            ></input>
                            { (this.state.nume === -1) && <span className="help-block text-danger">Acest câmp este obligatoriu!</span>}
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col'>
                            <input
                                className={`form-control ${this.state.mail === -1 ? 'is-invalid' : ''}`}
                                type='text'
                                name='mail'
                                placeholder='Adresă de email'
                                onChange={e => this.setState({ mail: e.target.value })}
                                disabled
                            ></input>
                            { (this.state.mail === -1) && <span className="help-block text-danger">Adresa de email nu este validă!</span>}
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col'>
                            <textarea
                                className={`form-control ${this.state.mesaj === -1 ? 'is-invalid' : ''}`}
                                name='mesaj'
                                placeholder='Mesaj'
                                onChange={e => this.setState({ mesaj: e.target.value })}
                                disabled
                            ></textarea>
                            { (this.state.mesaj === -1) && <span className="help-block text-danger">Acest câmp este obligatoriu!</span>}
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col'>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className={`form-check-input ${this.state.gdpr === -1 ? 'is-invalid' : ''}`}
                                    id="exampleCheck1"
                                    name='gdpr'
                                    onChange={e => this.setState({ gdpr: e.target.checked })}
                                    disabled
                                ></input>
                                <label className="form-check-label" htmlFor="exampleCheck1">Prin trimiterea acestui formular sunt de acord cu <a href="http://shop.iedemaramu.ro/politica-de-confidentialitate">Termenii și Condițiile Ie De Maramu</a></label>
                            </div>
                            { (this.state.gdpr === -1) && <span className="help-block text-danger text-center">Acest câmp este obligatoriu!</span>}
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col text-center'>
                            <button className='btn btn-primary' type='submit' onClick={this.handleSubmit} disabled>Trimite!</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
