import React from 'react';
import { reviews } from './reviews_data';
import './reviews.scss';
import { isMobile } from "react-device-detect";

export default class Reviews extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedReview: 0,
        }
    }

    render() {
        const reviewItem = (
            <div className='d-block review-item border shadow-lg p-3 m-3 float-left'>
                <h3>{reviews[this.state.selectedReview].name}</h3>
                <p>{reviews[this.state.selectedReview].review}</p>
            </div>
        )
        const caretClassNames = 'fa fa-2x float-left arrow';
        return (
            <div className='container p-3 mt-5 mb-5'>
                <h3 className='text-center'>Ce părere au clienții noștri?</h3>
                <hr className='separator'></hr>
                <div className='container'>
                <div className='carousel'>
                    {!isMobile && <i className={`${caretClassNames } fa-caret-left ${this.state.selectedReview === 0 ? 'text-muted' : ''}`} data-goto={this.state.selectedReview - 1} onClick={this.handleClick}></i>}
                    {reviewItem}
                    {!isMobile && <i className={`${caretClassNames } fa-caret-right ${this.state.selectedReview === reviews.length - 1 ? 'text-muted' : ''}`} data-goto={this.state.selectedReview + 1} onClick={this.handleClick}></i>}
                    <br></br>
                    <div className='d-block circles container'>
                        <div className='row'>
                            {reviews.map((review, index) => {
                                const className = (index === this.state.selectedReview ? 'fa' : 'far') + ' fa-circle';
                                return (
                                    <div className='col m-1' key={`review-${index}`} data-goto={index} onClick={this.handleClick}>
                                        <i className={className}></i>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <p className='mt-1 text-center'>Pentru mai multe testimoniale, click <a target="_" href="https://www.facebook.com/pg/iedemaramu/reviews/">aici</a> !</p>
                </div>
                </div>
            </div>
        )
    }

    handleClick = (event) => {
        const goto = parseInt(event.currentTarget.dataset.goto);
        if (goto === -1 || goto === reviews.length) {
            return;
        }
        this.setState({
            selectedReview: goto,
        })
        window.clearTimeout(this.interval);
        this.interval = setInterval(this.changeReview, 5000);
    }

    changeReview = () => {
        let newReview = this.state.selectedReview + 1;
        if (newReview >= reviews.length) {
            newReview = 0;
        }
        this.setState({
            selectedReview: newReview,
        })
    }

    componentDidMount = () => {
        this.interval = setInterval(this.changeReview, 5000);
    }
}
