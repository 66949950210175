import React from 'react';
import './measurements.scss';
import { isMobile } from "react-device-detect";

export default class Measurements extends React.Component{
    render() {
        const imgClassName = isMobile ? 'w-100' : 'max-width-50';
        return (
            <div className='container p-3 mt-5 measurements-container'>
                <h1 className='text-center'>Ghiduri de mărimi</h1>
                <hr className='separator'></hr>
                <img src={require('./images/masuri-femei.jpg')} alt="Măsuri femei" className={imgClassName}></img>
                <img src={require('./images/masuri-barbati.jpg')} alt="Măsuri bărbați" className={imgClassName}></img>
            </div>
        )
    }
}