import React from 'react';
import { faqData } from './faq-data';
import FaqItem from './faq-item';

export default class Faq extends React.Component{
    render() {
        const faqItems = faqData.map((faq, index) => (<FaqItem {...faq} key={`faq-${index}`}></FaqItem>))
        return (
            <div className='container p-3 mt-5'>
                <h3 className='text-center'>Întrebări Frecvente</h3>
                <hr className='separator'></hr>
                <div className='container'>
                    {faqItems}
                </div>
            </div>
        )
    }
};