import React from 'react';
import './about.scss';
import AboutRow from './about-row';
import { aboutData } from './about-data';

class About extends React.Component {
    render = () => {
        let about = aboutData.map((aboutRow, index) => (
            <AboutRow {...aboutRow} key={`about-${index}`} placeImageRight={!!(index%2)}></AboutRow>
        ))
        return (
            <div className='about-container'>
                <div className='about-us-container'>
                    <div className='container about-us'>
                        <div className='row'>
                            <div className="col-md">
                                <div className="who">
                                    <h1>Cine suntem noi?</h1>
                                    <p>Povestea Ie de Maramu’ a început în noiembrie 2017 și de atunci crește încet, frumos și cu drag.</p>
                                    <img src='https://lh3.googleusercontent.com/pw/ACtC-3etM8r-ppDXC5995X_TFQ-dwwkFVsJlUHshBZrB4dlK8GhETZrXNbpAMuUoKaCcP7FmUUCpc5ozkDnLzlyfmBt7kpQmfbOoziv2QRAlhzY5vakCdMHe-2Rdd-OuaCBjvVj-WzV6lcNlBt7k4rfomR9c=w960-h559-no?authuser=0' alt='Maramures'/>
                                    <p>Poate v-am mai spus, dar ne bucurăm să împărtășim povestea de fiecare dată când ni se ivește ocazia, mai ales când avem spațiu infinit pe internet pentru a detalia toate aspectele.</p>
                                    <p>Mădălina, nepoata din această poveste, pe vremea studenției în București purta cu drag straie maramureșene. Colegele și prietenele ei au observat acest lucru și i-au cerut mai multe detalii. Cum și Mădălinei îi plac poveștile, a început să le povestească despre bunica Mărioara, care coase ii și alte articole vestimentare. Cu timpul, Mădălina și-a adus de la Cernești, Maramureș, din satul natal mai multe ii care s-au dovedit a fi tare apreciate.</p>
                                    <p>De aici până la primele comenzi a fost doar un pas și nici prin cap nu i-a trecut că în 2 ani de la terminarea studiilor în capitală se va întoarce în Maramureș și se va dedica acestui proiect.</p>
                                    <p>Dorina, mama din această poveste, are la activ 20 de ani de croitorie, ea fiind femeia ce dă formă pânzei și coase toate elementele pentru a forma ia. A renunțat la croitorie acum ceva timp, cochetând o vreme cu activități diverse la o cabană în nordul Italiei, în vârful munților. Dorul de casă și de familie a adus-o în satul natal și de pe la final de 2017 se ocupă pe plan profesional exclusiv cu proiectul Ie de Maramu’.</p>
                                    <p>Mărioara, bunica din această poveste, ne-a insuflat tuturor pasiunea pentru portul popular. Plină de viață și mereu cu zâmbetul pe buze ne-a povestit la ceas aniversar cum a început ea să îndrăgească cusutul. Vă lăsăm mai jos, în caz că ați ratat, discuția cu ea.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h1 className='text-center mt-5'>Poveste cu bunica Mărioara</h1>
                    <hr className='separator'></hr>
                </div>
                { about }
                <div className='container about-footer'>
                    <div className="col-md text-justify shadow-lg p-3 rounded mt-5">
                        <p>Acesta a fost începutul a ceea ce înseamnă Ie de Maramu’ azi. O bunică, mamă, nepoată din Maramureș - toate trei cu roluri bine stabilite și straie moroșenești purtate cu drag la București.</p>
                        <p>Pe parcurs, și asta delimităm ca fiind noiembrie 2017 până în prezent, proiectul a crescut frumos, s-au alăturat oameni noi, am creat un brand, o identitate vizuală, o prezență în social media, în media locală și am dezvoltat modele de ii pornind de la cele ce se aflau încă în condiții excelente la Cernești, în lada de zestre a bunicii Mărioara.</p>
                        <p>Vă mai putem povesti despre cum căutăm modele noi sau de unde ne vine inspirația de a realiza feluri și feluri de produse, de cum am ajuns să trimitem ii în cam toate colțurile lumii, dar momentan ne oprim și ne auzim cu povești și altele detalii în curând, odată ce ne și împrietenim.</p>
                        <p>Cu drag de cusut și povestit, echipa Ie de Maramu’.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;