import React from 'react';
import './faq.scss';
import {Animated} from "react-animated-css";
import { isMobile } from "react-device-detect";

export default class FaqItem extends React.Component{
    constructor(props) {
        super(props);
        this.state = { expanded: false }
    }

    render() {
        const {question, answer} = this.props;
        const caretClassNames = 'fa mr-3 fa-caret-' + (this.state.expanded ? 'up' : 'down');
        const answerClassName = this.state.expanded ? 'd-block p-3 border answer' : 'd-none';
        const questionClassNames = `question-row row ${isMobile ? ' mt-3 w-100' : ' m-3 p-3'}`;
        return (
            <div className={questionClassNames} onClick={this.handleClick}>
                <h6 className='w-100'>
                    <i className={caretClassNames}></i>
                    {question}
                </h6>
                <div className='answer-container'>
                    <Animated animationIn="slideInDown" animationOut="slideOutUp" animationInDuration={1000} animationOutDuration={400} isVisible={this.state.expanded}>
                        <p className={answerClassName}>{answer}</p>
                    </Animated>
                </div>
            </div>
        )
    }

    handleClick = () => {
        this.setState({ expanded: !this.state.expanded });
    }
}