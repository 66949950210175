import React from 'react';
import './ie.scss';
import Header from '../header/header';
import About from '../about/about';
import Stages from '../stages/stages';
import Products from '../products/products';
import Reviews from '../reviews/reviews';
import Prices from '../prices/prices';
import Faq from '../faq/faq';
import Team from '../team/team';
import Contact from '../contact/contact';
import Measurements from '../measurements/measurements';
import Footer from '../footer/footer';
import {Animated} from "react-animated-css";
import { urlData } from './url-data';

class IeDeMaramu extends React.Component {
  render = () => {
    return (
      <div className="ie">
        <Header></Header>
        <section id='hero' className='main-container'>
          <h1>Ie de Maramu'</h1>
          <p>Ii tradiționale românești. Cusute cu drag de mâna bunicii!</p>
          <div className='btn d-block about-us-btn' data-goto='about-us-container' onClick={this.handleClick}>Despre Noi</div>
          <Animated animationIn="zoomInDown" animationInDuration={1000} isVisible={true} onAnimationEnd={this.test}>
            <img src={require('./images/logo.png')} alt="Logo Ie de Maramu'" className='logo'></img>
          </Animated>
        </section>
        <About></About>
        <Stages></Stages>
        <Products></Products>
        <Reviews></Reviews>
        <Prices></Prices>
        <Faq></Faq>
        <Team></Team>
        <Measurements></Measurements>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    );
  };

  componentDidMount = () => {
    document.title = 'Ie de Maramu\'';
    setTimeout(() => {
      const data = urlData();
      const currentHash = window.location.hash.replace('#','');
      if(data[currentHash]) {
        window.scrollTo({
          left: 0,
          top: data[currentHash].offestTop,
          behavior: 'smooth'
        })
      }
      window.addEventListener('scroll', this.handleScroll);
    }, 1000)
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleClick = (event) => {
    window.scrollTo({
        left: 0,
        top: document.getElementsByClassName(event.currentTarget.dataset.goto)[0].offsetTop -  document.getElementsByClassName('navbar')[0].offsetHeight,
        behavior: 'smooth'
    })
  }

  handleScroll = (event) => {
    const data = urlData();
    const scrollTop = document.documentElement.scrollTop;
    let urlFound = false;
    Object.keys(data).reverse().forEach((key) => {
      if (scrollTop >= data[key].offestTop && !urlFound) {
        window.location.hash = data[key].urlHash;
        urlFound = true;
      }
    })
    if (!urlFound) window.location.hash = 'acasa';
  }
};

export default IeDeMaramu;
