import React from 'react';
import './stages.scss';
import { stagesData } from './stages-data';
import { modelsData } from './models-data';
import Stage from './stage';
import Model from './model';
import { isMobile } from "react-device-detect";

class Stages extends React.Component {
    render = () => {
        const stages = stagesData.map((stage, index) => (
            <Stage stage={stage} index={index} key={`stage-${index}`}></Stage>
        ))

        return (
            <div className='stages-container'>
                <h1 className='text-center mt-5'>Ia, de la pânză la articol vestimentar</h1>
                <hr className='separator'></hr>
                <div className='container bg-light p-3 border'>
                    <p class-name='text-center'>Pentru a realiza o ie, noi avem nevoie de aproximativ 3-5 săptămâni. Termenul de realizare depinde de model, de mărimea iei și mai ales de tehnica de cusut aleasă.</p>
                    <p class-name='text-center'>Lucrăm cu drag și bucurie fiecare produs ce ne vine în mână. Folosim diferite tehnici de cusut: cusut în cruce, cu fir tras și cu ciur. Tehnicile sunt exact cele folosite în trecut de bunica și sunt cele mai des folosite în realizarea portului popular tradițional din zona noastră, a Maramureșului.</p>
                    <p class-name='text-center'>În decursul anilor am tot experimentat și testat modele noi, florale în cea mai mare parte și așa am ajuns să realizăm în 2019 o campanie online, de promovare a portului popular moroșenesc, unde am folosit 5 modele de ii care au fost tare mult apreciate. Acestea sunt prezentate mai jos.</p>
                </div>
                {
                    modelsData.map((model, index) => <Model {...model} key={`model_${index}`}></Model>)
                }
                <hr className='separator'></hr>
                <p className='text-center p-3'>Pentru ca tu să porți o ie, în forma ei finală, așa cum o știi, procesul de realizare al ei este mai complex, iar pânza trece prin mai multe etape.</p>
                <div className='container'>
                    {this.convertArrayToRows(stages, isMobile ? 1 : 3)}
                </div>
            </div>
        )
    }

    convertArrayToRows = (array, colsPerRow) => {
        const rows = [];
        for(let i = 0; i <= array.length; i += colsPerRow) {
            rows.push(
                <div className='row' key={`stages-row-${i}`}>
                    {array.slice(i, i + colsPerRow)}
                </div>
            )
        }
        return rows;
    }
}

export default Stages;
