import React from 'react';
import { isMobile } from "react-device-detect";

export default class Model extends React.Component {
    render() {
        return isMobile ? this.mobileContent() : this.desktopContent();
    }

    desktopContent() {
        const descriptionClassNames = `mt-5 ${this.props.description.includes('Mădălina') ? 'mt-7' : ''}`;
        return(
            <div className='container'>
                <div className='row mt-3'>
                    <div className='col text-right'>
                        <img src={require(`./images/${this.props.image}`)} alt={this.props.name}></img>
                    </div>
                <div className='col'>
                    <div className={descriptionClassNames}>
                        <h3>{this.props.name}</h3>
                        {
                            this.props.description ?
                            (
                                <div className='model-description border p-3 shadow-sm'>
                                    {this.props.description}
                                </div>
                            )
                            : null
                        }
                    </div>
                </div>
                </div>
            </div>
        )
    }

    mobileContent() {
        return (
            <div className='container'>
                <h3 className='text-center'>{this.props.name}</h3>
                <img src={require(`./images/${this.props.image}`)} alt={this.props.name}></img>
                {
                    this.props.description ?
                    (
                        <div className='model-description-mobile border p-3 shadow-sm mt-1'>
                            {this.props.description}
                        </div>
                    )
                    : null
                }
            </div>
        )
    }
}